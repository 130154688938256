import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";

let initialState = {
  man_power: false,
};

const slice = createSlice({
  name: "flag_state",
  initialState,
  reducers: {
    manPowerReducer: (flag_state, action) => {
      flag_state.man_power = action.payload.man_power;
    },
  },
});

const { manPowerReducer } = slice.actions;
export default slice.reducer;

// Action Creators

export const setManPower = (man_power) => (dispatch) => {
  dispatch(manPowerReducer({ man_power }));
};
