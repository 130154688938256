import React from "react";

import "./footer.scss";
import LeftSection from "./components/LeftSection";
import RightSection from "./components/RightSection";

function Footer() {
  return (
    <div className="footer_section">
      <div className="footer_inner">
        <div className="footer_left_section">
          <LeftSection />
        </div>
        <div className="footer_right_section">
          <RightSection />
        </div>
      </div>
    </div>
  );
}

export default Footer;
