import React, { useContext } from "react";
import "../styles/section4.scss";
import { ThemeContext } from "../../../ThemeContext";
import EarnSalary from "./EarnSalary/EarnSalary";
import FlyoverWhatDo from "./FlyoverWhatDo/FlyoverWhatDo";

function Section4(props) {
  const { theme } = useContext(ThemeContext);
  return (
    <div className="section4">
      <div className="title">
        <span style={{ color: theme.colors.red }}>Earn Extra Salary</span>
      </div>
      <div className="desc">Refer Jobs Earn Cash in three Step!</div>
      <div>
        <EarnSalary />
      </div>
      <FlyoverWhatDo />
    </div>
  );
}

export default Section4;
