import React, { memo, useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import "../styles/section1.scss";
import qr_Code from "../../../assets/images/image/home_qr.png";
import { ThemeContext } from "../../../ThemeContext";
import MegaMenu from "./MegaMenu/MegaMenu";

function Section1(props) {
  const { theme } = useContext(ThemeContext);
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleChange = (event) => {
    let inputNumber = event.target.value;
    setPhoneNumber(inputNumber);
    // if (/^\+[0-9]{1,3}[0-9]{6,14}$/.test(inputNumber)) {
    //   setPhoneNumber(inputNumber);
    // }
  };

  return (
    <div className="section1">
      <MegaMenu />
      <div className="main_body animate__animated animate__slideInUp">
        <div className="left_section">
          <div className="title_div">
            <span className="title" style={{ color: theme.colors.grey }}>
              Your Essential Platform for
            </span>
            <span
              className="title"
              style={{ color: theme.colors.red, marginLeft: "0.8vw" }}
            >
              Grey Collars
            </span>
          </div>
          <div className="body_input">
            <div className="body_input_inner">
              <div style={{ display: "flex", flexDirection: "row" }}>
                <input
                  type="text"
                  value={phoneNumber}
                  name={phoneNumber}
                  onChange={handleChange}
                  maxLength={10}
                  placeholder="WhatsApp number"
                  className={`whatsapp-input ${phoneNumber ? "valid" : ""}`}
                />
                <button className="right_arrow_btn">
                  {" "}
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    className="faArrowRight"
                  />
                </button>
              </div>
              <div>
                <button className="facebook" style={{ marginTop: "3vw" }}>
                  Facebook
                </button>
                <button className="gmail">Gmail</button>
                <button className="linkedin">LinkedIn</button>
              </div>
            </div>
            <div className="flexrow_main">
              <div className="flexrow">
                <div>
                  <div className="grey_div">
                    Connect with your Industries and Professionals
                  </div>
                  <div className="grey_div">
                    Find the Verified Jobs, get hired
                  </div>
                  <div className="grey_div">Manage ongoing Recruitment</div>
                  <div className="grey_div">
                    Build your own Identity in any Industires
                  </div>
                  <div className="grey_div" style={{ fontWeight: "bold" }}>
                    And More...
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right_section">
          <div className="right_section_inner">
            <div>
              <img src={qr_Code} className="qr_code" alt="qr_code" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(Section1);
