import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./slider.scss";
import SliderContainer from "../SliderContainer/SliderContainer";
import {
  manpower_left_data,
  manpower_slide_2_left_data,
  manpower_slide_3_left_data,
  manpower_slide_4_left_data,
  manpower_slide_5_left_data,
} from "../../../../utils/constants";
import { ReactComponent as BackSVG } from "../../../../assets/images/svg/slider_back.svg";
import { ReactComponent as NextSVG } from "../../../../assets/images/svg/slider_forward.svg";

const Slider = () => {
  return (
    <div className="slider_container">
      <Carousel
        showThumbs={false}
        infiniteLoop={true}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <button
              type="button"
              onClick={onClickHandler}
              title={label}
              className="slider-button slider-button-prev"
            >
              <BackSVG className="back_slider" />
            </button>
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <button
              type="button"
              onClick={onClickHandler}
              title={label}
              className="slider-button slider-button-next"
            >
              <NextSVG className="next_slider" />
            </button>
          )
        }
      >
        <div className="slider1">
          <SliderContainer
            data={manpower_left_data}
            slider_content={true}
            slider={1}
          />
        </div>
        <div className="slider2">
          <SliderContainer
            data={manpower_slide_2_left_data}
            slider_content={false}
            slider={2}
          />
        </div>
        <div className="slider3">
          <SliderContainer
            data={manpower_slide_3_left_data}
            slider_content={false}
            slider={3}
          />
        </div>
        <div className="slider4">
          <SliderContainer
            data={manpower_slide_4_left_data}
            slider_content={false}
            slider={4}
          />
        </div>
        <div className="slider5">
          <SliderContainer
            data={manpower_slide_5_left_data}
            slider_content={false}
            slider={5}
          />
        </div>
      </Carousel>
    </div>
  );
};

export default Slider;
