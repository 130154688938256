import React from "react";
import { ReactComponent as ProfileSVG } from "../../../assets/images/svg/slide_2_right_2.svg";

function RightSec2() {
  return (
    <div className="details_div">
      <div className="details_title">Document Management</div>
      <div className="details_desc">
        Document Suggestions to agencies, companies, and applicants based on
        their current status, and follow up on the documents until they are
        uploaded, You can Save applicant documents, share them with clients,
        generate a link, and add staff to manage and upload the documents
        project-wise. and many more....
      </div>
      <div className="transparent_btn_div">
        <button type="submit" className="transparent_btn">
          Set Document Reminer
        </button>
      </div>
      <div className="profile_right_div">
        <ProfileSVG className="slide_2_right_2_profile_svg" />
      </div>
    </div>
  );
}

export default RightSec2;
