import React, { memo, useContext } from "react";
import "../styles/section3.scss";
import { ThemeContext } from "../../../ThemeContext";
import GlobalCompany from "./GlobalCompany/GlobalCompany";
import ManageNetwork from "./ManageNetwork/ManageNetwork";

function Section3(props) {
  const { theme } = useContext(ThemeContext);
  return (
    <div className="section3">
      <div className="grey_body">
        <div className="first_item">
          <div className="title">
            <span style={{ color: theme.colors.red }}>
              Post your Job Requirement
            </span>
          </div>
          <div className="title secondTxt">
            Get Sortlisted Cv’s in your mail
          </div>
        </div>
        <div className="second_item">
          <div className="flexRow">
            <div>
              <button className="whatsappLinkBtn postJobBtn">post job</button>
            </div>
            <div className="whatsappLinkBtnDiv">
              <button className="whatsappLinkBtn">w.a/Whatsapp link</button>
            </div>
          </div>
        </div>
      </div>
      <div className="global_companies">
        <GlobalCompany />
      </div>
      <div className="manage_companies">
        <ManageNetwork />
      </div>
    </div>
  );
}

export default memo(Section3);
