const theme = {
  colors: {
    primary: "#5463FF",
    secondary: "#FFFFFF",
    grey: "#798291",
    red: "#E8222A",
    black: "#000",
    inputBorder: "#C4C4C4",
    inputBg: "#F3F3F3",
    // Add more color variables as needed
  },
  fontSizes: {
    small: {
      mobile: "12px",
      tablet: "14px",
      desktop: "16px",
    },
    medium: {
      mobile: "16px",
      tablet: "18px",
      desktop: "20px",
    },
    large: {
      mobile: "20px",
      tablet: "22px",
      desktop: "24px",
    },
    // Add more font size variables as needed
  },
  typography: {
    fontFamily: "Montserrat, sans-serif",
    fontWeight: {
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
    },
    // Add more typographic properties as needed
  },
};

export default theme;
