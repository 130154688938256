import React, { memo } from "react";
import { ReactComponent as WhiteLogo } from "../../../assets/images/logo/white_Logo.svg";
import { ReactComponent as TwitterSVG } from "../../../assets/images/svg/twitter_white.svg";
import { ReactComponent as LinkdinSVG } from "../../../assets/images/svg/linkedin-white.svg";
import { ReactComponent as InstaSVG } from "../../../assets/images/svg/insta_white.svg";
import { ReactComponent as QRSVG } from "../../../assets/images/svg/QR_white.svg";

function LeftSection() {
  return (
    <div className="left_section_inner">
      <div className="white_logo_div">
        <WhiteLogo className="white_logo" />
      </div>
      <div className="social_media_div">
        <div className="social_logo_div">
          <p className="follow_desc">Follow Us</p>
          <div className="social_logo_div_inner">
            <TwitterSVG className="social_logo" />
            <LinkdinSVG className="social_logo" />
            <InstaSVG className="social_logo" />
          </div>
        </div>
      </div>
      <div className="qr_div">
        <QRSVG className="qr_logo" />
        <p className="follow_desc">Scan Whatsup</p>
      </div>
    </div>
  );
}

export default memo(LeftSection);
