import React from "react";
import "./ManPower.scss";
import ManPowerSection1 from "./components/ManPowerSection1";
import ManPowerSection2 from "./components/ManPowerSection2";
import ManPowerSection3 from "./components/ManPowerSection3";
import ManPowerSection4 from "./components/ManPowerSection4";
import ManPowerSection5 from "./components/ManPowerSection5";
import ManPowerSection6 from "./components/ManPowerSection6";

function ManPower() {
  return (
    <div>
      <div className="man_power_container">
        <ManPowerSection1 />
      </div>
      <div className="complete_sol">
        <ManPowerSection2 />
      </div>
      <div className="cv_bank_section">
        <ManPowerSection3 />
      </div>
      <div className="intw_section">
        <ManPowerSection4 />
      </div>
      <div className="cv_basket">
        <ManPowerSection5 />
      </div>
      <div className="contact_section">
        <ManPowerSection6 />
      </div>
    </div>
  );
}

export default ManPower;
