import React from "react";
import { ReactComponent as Slide3Right3SVG } from "../../../assets/images/svg/slide3_right_3.svg";

function RightSec3() {
  return (
    <div className="details_div">
      <div className="details_title">Project Automation</div>
      <div className="details_desc">
        From Post job till Deploy Candidates everything on one Dashboard whether
        its a Cv’s Selections or Self or Client Interview track every thing at
        one place, check the project health, forcaste the project analyses the
        project insight to take a quick and smart decision.
      </div>
      <div className="transparent_btn_div">
        <button type="submit" className="transparent_btn">
          Start Project
        </button>
      </div>
      <div className="profile_right_div">
        <Slide3Right3SVG className="slide_3_right_3" />
      </div>
    </div>
  );
}

export default RightSec3;
