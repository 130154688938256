import React, { useState } from "react";
import "../styles/man_pow_sec6.scss";
import { ReactComponent as FlagIndiaSvg } from "../../../assets/images/svg/flag_india.svg";

function ManPowerSection6() {
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleChange = (event) => {
    let inputNumber = event.target.value;
    setPhoneNumber(inputNumber);
    // if (/^\+[0-9]{1,3}[0-9]{6,14}$/.test(inputNumber)) {
    //   setPhoneNumber(inputNumber);
    // }
  };
  return (
    <div className="man_pow_sec6">
      <div className="contact_title_div">
        <div className="title">Flyover is Exclusively Build for You!</div>
      </div>
      <div className="contact_center_body">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span className="input_flag_div">
            +91 <FlagIndiaSvg className="flag_svg" />
            <input
              type="text"
              value={phoneNumber}
              name={phoneNumber}
              onChange={handleChange}
              maxLength={10}
              placeholder="Type Whatsapp Number"
              className={`whatsapp-input ${phoneNumber ? "valid" : ""}`}
            />
          </span>
          <div className="btn_div">
            <button
              className="link_btn"
              style={{ textDecorationLine: "underline" }}
            >
              w.a/Whatsapp link
            </button>
            <button className="link_btn">Sign in</button>
          </div>
        </div>
      </div>
      <div className="post_job_div">
        <div className="post_job_inner">
          <div className="post_job_left">
            <div className="post_title">Post your vacancy</div>
            <div className="post_desc">Get Sortlisted Cv’s in your mail</div>
          </div>
          <div className="post_btn_div">
            <button className="post_btn">Sign in</button>
            <button className="whatsapp_btn">w.a/Whatsapp link</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManPowerSection6;
