import React, { memo } from "react";
import "./filter.scss";
import SearchInput from "../../../../components/SearchInput/SearchInput";
import FilterBar from "../../../../components/FilterBar/FilterBar";

function Filter() {
  return (
    <div className="filter_container">
      <div className="filter_row">
        <SearchInput />
        <FilterBar />
      </div>
    </div>
  );
}

export default memo(Filter);
