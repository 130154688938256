import React from "react";
import axios from "axios";
import { useSelector } from "react-redux";

import * as actions from "../api";
import {baseURL} from "../../env";

const api = ({ dispatch }) => (next) => async (action) => {
  console.log("in api middleware...", action);

  if (action.type !== actions.apiCallBegan.type) return next(action);
  const {
    url,
    method,
    headers,
    data,
    onStart,
    onSuccess,
    onError,
  } = action.payload;
  if (onStart) dispatch({ type: onStart });
  next(action);
  try {
    console.log(
      " Invoking API ",
      baseURL + url,
      " at ",
      new Date()
    );
    const response = await axios.request({
      baseURL: baseURL,
      url,
      method,
      data,
      headers: {
        "X-Api-Key": environment.apiKey,
        channel: "MOBILE",
        "Content-Type": "application/json",
        ...headers,
      },
    });
    console.log(
      " API ",
      baseURL + url,
      " success response at ",
      new Date()
    );
    dispatch(actions.apiCallSuccess(response.data));
    if (onSuccess) dispatch({ type: onSuccess, payload: response.data });
  } catch (error) {
    console.log(
      " API ",
      baseURL + url,
      " error response at ",
      new Date()
    );
    //dispatch(actions.apiCallFailed(error.message));
    // if (onError) dispatch({ type: onError, payload: error.message });
    console.log("API Error:");
    console.log(error.message);
    console.log(JSON.stringify(error));
    dispatch(actions.apiCallFailed(error.message));
    if (onError) dispatch({ type: onError, payload: error });
  }
};

export default api;
