import React, { useContext } from "react";
import SignUpForm from "./SignUPForm/SignUp";
import { ThemeContext } from "../../../ThemeContext";
import "../styles/man_pow_sec2.scss";
import SearchBar from "./SearchBar/SearchBar";
import Slider from "./Slider/Slider";

function ManPowerSection2() {
  const { theme } = useContext(ThemeContext);
  return (
    <div className="man_pow_sec2">
      <div className="man_pow_sec2_body">
        <div className="title_div">
          <SearchBar />
          <div className="search_txt">Search Solution</div>
        </div>
        <div className="man_pow2_tile">Because we understand your world</div>
        <div className="slider_div_section">
          <div className="man_pow2_tile" style={{ color: theme.colors.black }}>
            Complete Solution
          </div>
          <Slider />
        </div>
      </div>
    </div>
  );
}

export default ManPowerSection2;
