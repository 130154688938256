import React from "react";
import { ReactComponent as ManPowerSvg } from "../assets/images/svg/man_power.svg";
import { ReactComponent as CommunitySvg } from "../assets/images/svg/community.svg";
import { ReactComponent as VerifiedSvg } from "../assets/images/svg/verfied.svg";
import { ReactComponent as IndustrySvg } from "../assets/images/svg/industry.svg";
import { ReactComponent as UpgradeSvg } from "../assets/images/svg/upgrade.svg";
import { ReactComponent as ReferralSvg } from "../assets/images/svg/referral.svg";

import { ReactComponent as BlogSvg } from "../assets/images/svg/blog.svg";
import { ReactComponent as PartnerSvg } from "../assets/images/svg/partner.svg";
import { ReactComponent as WhatsAppSvg } from "../assets/images/svg/whatsapp_icon.svg";
import { ReactComponent as HelpSupportSvg } from "../assets/images/svg/help_support.svg";
import { ReactComponent as MentorSvg } from "../assets/images/svg/mentor.svg";
import { ReactComponent as DashboardSvg } from "../assets/images/svg/dashboard.svg";

import { ReactComponent as MP_1_Svg } from "../assets/images/svg/mp_1.svg";
import { ReactComponent as MP_2_Svg } from "../assets/images/svg/mp_2.svg";
import { ReactComponent as MP_3_Svg } from "../assets/images/svg/mp_3.svg";
import { ReactComponent as MP_4_Svg } from "../assets/images/svg/mp_4.svg";

import { ReactComponent as Slide2MP_1_Svg } from "../assets/images/svg/slide_2_mp_1.svg";
import { ReactComponent as Slide2MP_2_Svg } from "../assets/images/svg/slide_2_mp_2.svg";
import { ReactComponent as Slide2MP_3_Svg } from "../assets/images/svg/slide_2_mp_3.svg";
import { ReactComponent as Slide2MP_4_Svg } from "../assets/images/svg/slide_2_mp_4.svg";
import { ReactComponent as Slide2MP_5_Svg } from "../assets/images/svg/slide_2_mp_5.svg";
import { ReactComponent as Slide2MP_6_Svg } from "../assets/images/svg/slide_2_mp_6.svg";

import { ReactComponent as Slide3MP_1_Svg } from "../assets/images/svg/slide_3_mp_1.svg";
import { ReactComponent as Slide3MP_2_Svg } from "../assets/images/svg/slide_3_mp_2.svg";
import { ReactComponent as Slide3MP_3_Svg } from "../assets/images/svg/slide_3_mp_3.svg";
import { ReactComponent as Slide3MP_4_Svg } from "../assets/images/svg/slide_3_mp_4.svg";
import { ReactComponent as Slide3MP_5_Svg } from "../assets/images/svg/slide_3_mp_5.svg";
import { ReactComponent as Slide3MP_6_Svg } from "../assets/images/svg/slide_3_mp_6.svg";

import { ReactComponent as Slide4MP_1_Svg } from "../assets/images/svg/slide4_mp_1.svg";
import { ReactComponent as Slide4MP_2_Svg } from "../assets/images/svg/slide4_mp_2.svg";
import { ReactComponent as Slide4MP_3_Svg } from "../assets/images/svg/slide4_mp_3.svg";
import { ReactComponent as Slide4MP_4_Svg } from "../assets/images/svg/slide4_mp_4.svg";
import { ReactComponent as Slide4MP_5_Svg } from "../assets/images/svg/slide4_mp_5.svg";

import { ReactComponent as Slide5MP_1_Svg } from "../assets/images/svg/slide_5_mp_1.svg";
import { ReactComponent as Slide5MP_2_Svg } from "../assets/images/svg/slide_5_mp_2.svg";
import { ReactComponent as Slide5MP_3_Svg } from "../assets/images/svg/slide_5_mp_3.svg";
import { ReactComponent as Slide5MP_4_Svg } from "../assets/images/svg/slide_5_mp_4.svg";
import { ReactComponent as Slide5MP_5_Svg } from "../assets/images/svg/slide_5_mp_5.svg";

export const MAX_WIDTH = 600;
export const footer_link_data = [
  {
    id: 1,
    title: "Products",
    links: [
      {
        parent_id: 1,
        id: 1,
        link_title: "Customer service",
      },
      {
        parent_id: 1,
        id: 2,
        link_title: "Client service",
      },
      {
        parent_id: 1,
        id: 3,
        link_title: "Inhouse productivity",
      },
      {
        parent_id: 1,
        id: 4,
        link_title: "Project delivering",
      },
      {
        parent_id: 1,
        id: 5,
        link_title: "Sales tracking.",
      },
      {
        parent_id: 1,
        id: 6,
        link_title: "Costing reduction",
      },
    ],
  },
  {
    id: 2,
    title: "Quick Links",
    links: [
      {
        parent_id: 2,
        id: 1,
        link_title: "Tutotrial",
      },
      {
        parent_id: 2,
        id: 2,
        link_title: "Recruiter",
      },
      {
        parent_id: 2,
        id: 3,
        link_title: "Jobs",
      },
      {
        parent_id: 2,
        id: 4,
        link_title: "Problem and Solution",
      },
      {
        parent_id: 2,
        id: 5,
        link_title: "Interview Campaign",
      },
      {
        parent_id: 2,
        id: 6,
        link_title: "WhatsApp Service",
      },
    ],
  },
  {
    id: 3,
    title: "Foreign Service",
    links: [
      {
        parent_id: 3,
        id: 1,
        link_title: "Mega Recruitment Co",
      },
      {
        parent_id: 3,
        id: 2,
        link_title: "Project Holding Company",
      },
      {
        parent_id: 3,
        id: 3,
        link_title: "Shutdown Project",
      },
      {
        parent_id: 3,
        id: 4,
        link_title: "Project Consultant",
      },
      {
        parent_id: 3,
        id: 5,
        link_title: "Independent Community",
      },
      {
        parent_id: 3,
        id: 6,
        link_title: "Survey",
      },
    ],
  },
  {
    id: 4,
    title: "Features",
    links: [
      {
        parent_id: 4,
        id: 1,
        link_title: "Tutotial",
      },
      {
        parent_id: 4,
        id: 2,
        link_title: "Independent Database",
      },
      {
        parent_id: 4,
        id: 3,
        link_title: "Cvs Databae",
      },
      {
        parent_id: 4,
        id: 4,
        link_title: "Rating and Feedback",
      },
      {
        parent_id: 4,
        id: 5,
        link_title: "Community",
      },
      {
        parent_id: 4,
        id: 6,
        link_title: "Lead Generation",
      },
    ],
  },
  {
    id: 5,
    title: "Services",
    links: [
      {
        parent_id: 5,
        id: 1,
        link_title: "Job Posting",
      },
      {
        parent_id: 5,
        id: 2,
        link_title: "Skill Training",
      },
      {
        parent_id: 5,
        id: 3,
        link_title: "Insttructor",
      },
      {
        parent_id: 5,
        id: 4,
        link_title: "Prize and Value",
      },
      {
        parent_id: 5,
        id: 5,
        link_title: "Advertisment",
      },
      {
        parent_id: 5,
        id: 6,
        link_title: "Associates/Agents",
      },
    ],
  },
];

export const feature_data = [
  {
    id: 1,
    title: "Manpower Solution",
    description:
      "We understand the unique needs of Gulf recruitment agencies, and our platform is tailored to meet those needs.",
    icon: <ManPowerSvg className="card_svg svg_man_power" />,
    main_tab: "feature",
  },
  {
    id: 2,
    title: "Community  Power",
    description:
      "we unlock their full potential and build stronger, more resilient communities for all.",
    icon: <CommunitySvg className="card_svg svg_community" />,
    main_tab: "feature",
  },
  {
    id: 3,
    title:
      "Verified and Genuine Agency, Companies, Skill provider Agents & Applicants",
    description:
      "We ensure authenticity of agencies, companies, skill providers and applicants on our platform. All are verified and genuine",
    icon: <VerifiedSvg className="card_svg svg_verifed" />,
    main_tab: "feature",
  },
  {
    id: 4,
    title: "Industry’s Insight",
    description:
      "Gain valuable insights into your industry with our platform. Stay up-to-date with the latest trends, news, and analysis to stay ahead of the competition",
    icon: <IndustrySvg className="card_svg svg_industry" />,
    main_tab: "feature",
  },
  {
    id: 5,
    title: "Upgrade Technical Skills",
    description:
      "Upgrade your technical skills with our comprehensive training programs. Learn the latest tools and techniques to stay competitive in your industry",
    icon: <UpgradeSvg className="card_svg svg_upgrade" />,
    main_tab: "feature",
  },
  {
    id: 6,
    title: "Referrals and Trainers Earn",
    description:
      "Earn through our referral program or as a trainer on our platform. Help others improve their skills or referring jobs while earning money at the same time",
    icon: <ReferralSvg className="card_svg svg_referral" />,
    main_tab: "feature",
  },
];

export const resource_data = [
  {
    id: 1,
    title: "Blog",
    description:
      "Create Independent Cv’s Bank, Post job opening and Manage ongoing Recruitment Project.",
    icon: <BlogSvg className="card_svg svg_blog" />,
    main_tab: "resource",
  },
  {
    id: 2,
    title: "Become Partner",
    description:
      "Create Independent Cv’s Bank, Post job opening and Manage ongoing Recruitment Project.",
    icon: <PartnerSvg className="card_svg svg_partner" />,
    main_tab: "resource",
  },
  {
    id: 3,
    title: "Update On WhatsApp",
    description:
      "Create Independent Cv’s Bank, Post job opening and Manage ongoing Recruitment Project.",
    icon: <WhatsAppSvg className="card_svg svg_whatsapp" />,
    main_tab: "resource",
  },
  {
    id: 4,
    title: "Help and Support",
    description:
      "Create Independent Cv’s Bank, Post job opening and Manage ongoing Recruitment Project.",
    icon: <HelpSupportSvg className="card_svg svg_help_support" />,
    main_tab: "resource",
  },
  {
    id: 5,
    title: "Become Mentor",
    description:
      "Create Independent Cv’s Bank, Post job opening and Manage ongoing Recruitment Project.",
    icon: <MentorSvg className="card_svg svg_mentor" />,
    main_tab: "resource",
  },
  {
    id: 6,
    title: "One DashBoard",
    description:
      "Create Independent Cv’s Bank, Post job opening and Manage ongoing Recruitment Project.",
    icon: <DashboardSvg className="card_svg svg_dashboard" />,
    main_tab: "resource",
  },
];

export const prize_solution_data = [
  {
    id: 1,
    title: "Boost Job opening, Resume Profile, Agency’s Portfolio.",
    description:
      "Create Independent Cv’s Bank, Post job opening and Manage ongoing Recruitment Project.",
    icon: <BlogSvg className="card_svg svg_blog" />,
    main_tab: "prize_solution",
  },
  {
    id: 2,
    title: "Skill Training",
    description:
      "Create Independent Cv’s Bank, Post job opening and Manage ongoing Recruitment Project.",
    icon: <PartnerSvg className="card_svg svg_partner" />,
    main_tab: "prize_solution",
  },
  {
    id: 3,
    title: "Ongoing Recruitment Solution",
    description:
      "Create Independent Cv’s Bank, Post job opening and Manage ongoing Recruitment Project.",
    icon: <WhatsAppSvg className="card_svg svg_whatsapp" />,
    main_tab: "prize_solution",
  },
  {
    id: 4,
    title: "Foreign Company’s Recruitment Solution",
    description:
      "Create Independent Cv’s Bank, Post job opening and Manage ongoing Recruitment Project.",
    icon: <HelpSupportSvg className="card_svg svg_help_support" />,
    main_tab: "prize_solution",
  },
  {
    id: 5,
    title: "Become Mentor and Trainer",
    description:
      "Create Independent Cv’s Bank, Post job opening and Manage ongoing Recruitment Project.",
    icon: <MentorSvg className="card_svg svg_mentor" />,
    main_tab: "prize_solution",
  },
  {
    id: 6,
    title: "Company Branding and Recognization",
    description:
      "Create Independent Cv’s Bank, Post job opening and Manage ongoing Recruitment Project.",
    icon: <DashboardSvg className="card_svg svg_dashboard" />,
    main_tab: "prize_solution",
  },
];

export const manpower_left_data = [
  {
    id: 1,
    description: "Verified Agencies, Companies, and Skill Provider Agents",
    icon: <MP_1_Svg className="mp_ mp_1_svg" />,
  },
  {
    id: 2,
    description:
      "Genuine ratings, review and feedback provided by other users to contribute to the community.",
    icon: <MP_2_Svg className="mp_ mp_2_svg" />,
  },
  {
    id: 3,
    description:
      "evaluate the character of the company before entering into Business",
    icon: <MP_3_Svg className="mp_ mp_3_svg" />,
  },
  {
    id: 4,
    description:
      "Examine the proficiency of users prior to assigning them a project.",
    icon: <MP_4_Svg className="mp_ mp_4_svg" />,
  },
];

export const manpower_slide_2_left_data = [
  {
    id: 1,
    description:
      "Manage Ongoing and Completed Project Documents and sort them by company.",
    icon: <Slide2MP_1_Svg className="mp_ mp_1_svg" />,
  },
  {
    id: 2,
    description:
      "Schedule a reminder for document expiry or uploading, or delegate document uploading tasks to in-house or colleague staff",
    icon: <Slide2MP_2_Svg className="mp_ mp_2_svg" />,
  },
  {
    id: 3,
    description:
      "Once Click share, view, edit options, save it on cloud without cost",
    icon: <Slide2MP_3_Svg className="mp_ mp_3_svg" />,
  },
  {
    id: 4,
    description:
      "View Documents offline whenver and wherever you need urgently",
    icon: <Slide2MP_4_Svg className="mp_ mp_4_svg" />,
  },
  {
    id: 5,
    description: "Generate a PDF of a set of documents with just one click.",
    icon: <Slide2MP_5_Svg className="mp_ mp_3_svg" />,
  },
  {
    id: 6,
    description: "Effective teamwork in managing projects and documents.",
    icon: <Slide2MP_6_Svg className="mp_ mp_4_svg" />,
  },
];

export const manpower_slide_3_left_data = [
  {
    id: 1,
    description:
      "Easily manage the process from viewing resumes to approving them, whether it's for oneself or a client, without any confusion.",
    icon: <Slide3MP_1_Svg className="mp_ mp_1_svg" />,
  },
  {
    id: 2,
    description:
      "keep a bull eyes on each activity done by Applicants, even by Team",
    icon: <Slide3MP_2_Svg className="mp_ mp_2_svg" />,
  },
  {
    id: 3,
    description:
      "Create, track, Assign A task base upon project, stay focus deliver best.",
    icon: <Slide3MP_3_Svg className="mp_ mp_3_svg" />,
  },
  {
    id: 4,
    description:
      "Effortlessly generate project reports to share with clients and track their progress, enabling smart decision-making",
    icon: <Slide3MP_4_Svg className="mp_ mp_4_svg" />,
  },
  {
    id: 5,
    description:
      "Access a free basket of relevant applicant CVs based on posted jobs",
    icon: <Slide3MP_5_Svg className="mp_ mp_3_svg" />,
  },
  {
    id: 6,
    description:
      "Respond promptly to all inquiries, whether from newly applied applicants or clients suggesting live advice",
    icon: <Slide3MP_6_Svg className="mp_ mp_4_svg" />,
  },
];

export const manpower_slide_4_left_data = [
  {
    id: 1,
    description:
      "Add a Coulige or supportive user temporarily until the query is completed.",
    icon: <Slide4MP_1_Svg className="mp_ mp_1_svg" />,
  },
  {
    id: 2,
    description:
      "Consolidate all project-related conversations in one central location for seamless completion",
    icon: <Slide4MP_2_Svg className="mp_ mp_2_svg" />,
  },
  {
    id: 3,
    description:
      "Effortlessly share live project updates or quick snapshots without any hassle.",
    icon: <Slide4MP_3_Svg className="mp_ mp_3_svg" />,
  },
  {
    id: 4,
    description: `Easily turn a message into a task or view to other user or group for a specific period just by simply tagging it with "@" address.`,
    icon: <Slide4MP_4_Svg className="mp_ mp_4_svg" />,
  },
  {
    id: 5,
    description:
      "stay organized and ensures that you never miss an important task or deadline or appointment, with our text reminder feature",
    icon: <Slide4MP_5_Svg className="mp_ mp_3_svg" />,
  },
];

export const manpower_slide_5_left_data = [
  {
    id: 1,
    description: "Organize a Virtual conference or meeting ",
    icon: <Slide5MP_1_Svg className="mp_ mp_1_svg" />,
  },
  {
    id: 2,
    description:
      "Experience the power of one job post for immense responses from relevant applicants, breaking barriers and connecting you directly.",
    icon: <Slide5MP_2_Svg className="mp_ mp_2_svg" />,
  },
  {
    id: 3,
    description:
      "Effortlessly add a temporary user on the go for quick decision-making, with hassle-free invitation options",
    icon: <Slide5MP_3_Svg className="mp_ mp_3_svg" />,
  },
  {
    id: 4,
    description: `Easily review awaiting and interviewed applicant profiles, providing live remarks to make informed decisions without any future confusion`,
    icon: <Slide5MP_4_Svg className="mp_ mp_4_svg" />,
  },
  {
    id: 5,
    description:
      "Preview the next candidate's profile before going live, making quick decisions to bring them onboard or reject them to save time",
    icon: <Slide5MP_5_Svg className="mp_ mp_3_svg" />,
  },
];
