import React from "react";
import "./earn_salary.scss";
import { ReactComponent as EarnSal1 } from "../../../../assets/images/svg/earn_sal1.svg";
import { ReactComponent as EarnSal2 } from "../../../../assets/images/svg/earn_sal2.svg";
import { ReactComponent as EarnSal3 } from "../../../../assets/images/svg/earn_sal3.svg";

function EarnSalary() {
  return (
    <div className="earn_salary">
      <div className="earn_sal_body">
        <div className="earn_sal_inner">
          <div className="svg_div">
            <EarnSal1 className="earn_sal_svg1" />
            <div className="desc_svg_title" style={{ marginLeft: "-10%" }}>
              search job
            </div>
          </div>
          <div className="svg_div">
            <EarnSal2 className="earn_sal_svg2" />
            <div className="desc_svg_title" style={{ marginLeft: "-8%" }}>
              refer in contacts
            </div>
          </div>
          <div className="svg_div">
            <EarnSal3 className="earn_sal_svg3" />
            <div className="desc_svg_title">earn cash</div>
          </div>
          <div className="line_div" />
        </div>
      </div>
    </div>
  );
}

export default EarnSalary;
