import React from "react";
import { ReactComponent as ProfileSVG } from "../../../assets/images/svg/profile_card_user.svg";

function RightSec1() {
  return (
    <div className="details_div">
      <div className="details_title">Company Portfolio</div>
      <div className="details_desc">
        Differentiate yourself from other typical companies by developing your
        own branding and recognition through a portfolio that represents your
        agency throughout the Middle East with just a click. Your verified tags,
        personal CVs bank, company's nature, and service expertise are
        sufficient to showcase your core competencies and capabilities.
      </div>
      <div className="transparent_btn_div">
        <button type="submit" className="transparent_btn">
          Create Portfolio
        </button>
      </div>
      <div className="profile_right_div">
        <ProfileSVG className="profile_svg" />
      </div>
    </div>
  );
}

export default RightSec1;
