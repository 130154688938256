import React from "react";
import { footer_link_data } from "../../../utils/constants";

function FooterLinks(props) {
  return (
    <div className="footer_link_section">
      {footer_link_data.map((item, i) => (
        <div className="footer_links_inner" key={i}>
          <div className="footer_product_title">{item.title}</div>
          {item.links.map((link_item, link_index) => (
            <div
              key={link_index}
              className="footer_inner_link_list"
              style={{ marginTop: item.id == link_item.parent_id ? 0 : "1vw" }}
            >
              <a href="/" className="footer_link">
                {link_item.link_title}
              </a>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}

export default FooterLinks;
