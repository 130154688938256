import React from "react";
import "./flyover_what_do.scss";

function FlyoverWhatDo() {
  return (
    <div className="flyover_what_do">
      <div className="flyover_what_do_gry">
        <div className="title">What We Do</div>
        <p className="desc_txt">
          Flyover.work is platform for grey and blue-collar Professionals Focus
          to motive. standard living, enhancing skills, finding and providing
          solutions of their respective experienced area, and building a
          network.
          <br />
          <br />
          For Recruiter we aim to swift the recruiting till Deploying Solution.
          We connect large talent pool of network with the relevant Industries.
          <br />
          <br />
          Flyover.work is a product of Umid Group of Company Base in Mumbai and
          Registered under State Govt Body
        </p>
      </div>
    </div>
  );
}

export default FlyoverWhatDo;
