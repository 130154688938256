import React, { memo, useState } from "react";
import "./popular_section_card.scss"; // CSS file for styling the Card component
import { ReactComponent as WhatsappRed } from "../../../../assets/images/svg/whatsapp_shadow.svg";
import { ReactComponent as ConnectionRed } from "../../../../assets/images/svg/connection_red.svg";
import { ReactComponent as InteractionRed } from "../../../../assets/images/svg/interaction_red.svg";
import { ReactComponent as AcessRed } from "../../../../assets/images/svg/acess_red.svg";

const PopularCard = (props) => {
  const [popular_card_data, setCardData] = useState([
    {
      id: 1,
      icon: <WhatsappRed className="card-icon" />,
      title: (
        <p className="title">
          Gulf Opening
          <br /> Direct On whatsapp
        </p>
      ),
    },
    {
      id: 2,
      icon: <ConnectionRed className="card-icon" />,
      title: (
        <p className="title">
          Your Industry/Job
          <br /> Connection
        </p>
      ),
    },
    {
      id: 3,
      icon: <InteractionRed className="card-icon" />,
      title: (
        <p className="title">
          Your Language
          <br /> Interaction
        </p>
      ),
    },
    {
      id: 4,
      icon: <AcessRed className="card-icon" />,
      title: (
        <p className="title">
          Verified Job
          <br /> and Company’s Acess
        </p>
      ),
    },
  ]);

  return (
    <div className="card-container">
      <div className="bottom_bar">
        <div className="card_row">
          {popular_card_data.map((item, i) => {
            return (
              <div key={i} className="popular_card_">
                <div className="icon_div">{item?.icon}</div>
                <div className="card-content">
                  <div>{item?.title}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default memo(PopularCard);
