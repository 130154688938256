import React, { useContext } from "react";
import SignUpForm from "./SignUPForm/SignUp";
import { ThemeContext } from "../../../ThemeContext";
import "../styles/man_pow_sec1.scss";

function ManPowerSection1() {
  const { theme } = useContext(ThemeContext);
  return (
    <div className="man_pow_sec1">
      <div className="man_pow_sec1_body">
        <div className="title_div_sign_up">
          <div className="title">
            Exclusive Platform For
            <br />
            <span style={{ color: theme.colors.primary }}>
              Gulf Recruitment
            </span>
            <br />
            Agencies
          </div>
          <div className="transparent_btn_div">
            <button type="submit" className="transparent_btn">
              Request Call back
            </button>
          </div>
        </div>
        <div className="sign_up_div">
          <SignUpForm />
        </div>
      </div>
    </div>
  );
}

export default ManPowerSection1;
