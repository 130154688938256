import React from "react";
import { ReactComponent as Slide5SVG } from "../../../assets/images/svg/slide5_right_5.svg";

function RightSec5() {
  return (
    <div className="details_div">
      <div className="details_title">Virtual Event Solution</div>
      <div className="details_desc">
        Conducting interviews has never been easier, with our user-friendly
        interface that allows you to schedule, conduct, and evaluate interviews
        all in one place in just one announcement . Our platform also provides
        you with detailed reports, giving you valuable insights into the
        performance of each applicant and helping you make informed hiring
        decisions.
      </div>
      <div className="transparent_btn_div">
        <button type="submit" className="transparent_btn">
          Create Event
        </button>
      </div>
      <div className="profile_right_div">
        <Slide5SVG className="slide_5_right_5_svg" />
      </div>
    </div>
  );
}

export default RightSec5;
