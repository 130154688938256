import React from "react";
import { useNavigate } from "react-router-dom";
import "./popup_modal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { setManPower } from "../../store/flag_state";

const PopupModal = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getStarted = (item, id) => {
    switch (item) {
      case "feature":
        loadScreen(id);
        break;

      case "resource":
        break;

      case "prize_solution":
        break;

      default:
        break;
    }
  };

  const loadScreen = (id) => {
    console.log("calll-------------");
    switch (id) {
      case 1:
        navigate("/man-power");
        break;

      default:
        break;
    }
  };

  return (
    <div className="card_container">
      {data.map((cardItem, i) => {
        return (
          <div
            onClick={() => getStarted(cardItem?.main_tab, cardItem?.id)}
            key={i}
            className="mega_menu_card"
          >
            <div className="card_icon">{cardItem?.icon}</div>
            <div className="card_title">{cardItem?.title}</div>
            <div className="card_description">{cardItem?.description}</div>
            <button
              className="card_button"
              onClick={() => getStarted(cardItem?.main_tab, cardItem?.id)}
            >
              Get Started
              <FontAwesomeIcon
                icon={faArrowRight}
                className="getStarted_icon"
              />
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default PopupModal;
