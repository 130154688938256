import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import "./sign_up.scss";
import { ThemeContext } from "../../../../ThemeContext";

function SignUpForm() {
  const { theme } = useContext(ThemeContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    alert("Form submitted!");
    // Access form data from the "data" object
    console.log(data);
  };

  return (
    <div className="signup_container">
      <div className="sign_up_title">Sign up</div>
      <label
        className="sign_up_lbl"
        style={{ fontFamily: "Montserrat", fontWeight: "400" }}
      >
        License Holding Manpower Agency
      </label>
      <form className="signup-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label className="sign_up_lbl" htmlFor="name">
            Your Name:
          </label>
          <input
            type="text"
            className="input_txt"
            style={{
              backgroundColor: theme.colors.inputBg,
              borderColor: theme.colors.inputBorder,
            }}
            id="name"
            placeholder="Name"
            {...register("name", { required: true })}
          />
          {errors.name && <span className="error">Name is required</span>}
        </div>
        <div className="form-group">
          <label className="sign_up_lbl" htmlFor="designation">
            Designation
          </label>
          <input
            type="text"
            className="input_txt"
            style={{
              backgroundColor: theme.colors.inputBg,
              borderColor: theme.colors.inputBorder,
            }}
            id="designation"
            placeholder="Designation"
            {...register("designation", { required: true })}
          />
          {errors.designation && (
            <span className="error">Designation is required</span>
          )}
        </div>
        <div className="form-group">
          <label className="sign_up_lbl" htmlFor="license">
            License No. Or Agency Name
          </label>
          <input
            type="text"
            className="input_txt"
            style={{
              backgroundColor: theme.colors.inputBg,
              borderColor: theme.colors.inputBorder,
            }}
            id="license"
            placeholder="license No."
            {...register("license", { required: true })}
          />
          {errors.license && (
            <span className="error">License No. is required</span>
          )}
        </div>
        <div className="form-row two_col_div">
          <div className="form-group two_col">
            <label className="sign_up_lbl" htmlFor="city">
              City
            </label>
            <input
              type="text"
              className="input_txt"
              style={{
                backgroundColor: theme.colors.inputBg,
                borderColor: theme.colors.inputBorder,
              }}
              id="city"
              placeholder="City"
              {...register("city", { required: true })}
            />
            {errors.city && <span className="error">City is required</span>}
          </div>
          <div className="form-group two_col">
            <label className="sign_up_lbl" htmlFor="pincode">
              Pincode
            </label>
            <input
              type="text"
              className="input_txt"
              style={{
                backgroundColor: theme.colors.inputBg,
                borderColor: theme.colors.inputBorder,
              }}
              id="pincode"
              placeholder="Pincode"
              {...register("pincode", { required: true })}
            />
            {errors.pincode && (
              <span className="error">Pincode is required</span>
            )}
          </div>
        </div>
        <div className="form-group">
          <button type="submit" className="sign_up_submit_btn">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default SignUpForm;
