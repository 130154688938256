import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";

const initialState = {
  data: {},
  routeName: "Login",
  loggedIn: false,
  loading: false,
  lastFetch: null,
  permissionName: null,
  permissionValue: null,
  latitide: null,
  longitude: null,
  message: null,
  status: undefined,
  appversion: null,
  error: {},
}

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loginRequested: (user, action) => {
      user.loading = true;
      user.status = undefined;
      user.message = null;
      user.data = {};
      user.error = {};
    },
    loginReceived: (user, action) => {
      user.data = action.payload.data;
      user.loggedIn = true;
      user.loading = false;
      user.lastFetch = Date.now();
      user.message = null;
      user.status = "success";
      user.error = {};
    },
    loginRequestFailed: (user, action) => {
      user.loading = false;
      user.loggedIn = false;
      user.status = "error";
      user.message = null;
      user.error =
        action.payload.response != null || action.payload.response != undefined
          ? action.payload.response.data
          : action.payload.message;
    },
    forgotPasswordRequested: (user, action) => {
      user.loading = true;
      user.message = null;
      user.status = undefined;
      user.error = {};
    },
    forgotPasswordReceived: (user, action) => {
      user.data = {};
      user.message = action.payload.message;
      user.routeName = "ResetPassword";
      user.loading = false;
      user.lastFetch = null;
      user.status = "success";
      user.error = {};
    },
    forgotPasswordRequestFailed: (user, action) => {
      user.loading = false;
      user.status = "error";
      user.message = null;
      user.routeName = "ResetPassword";
      user.error =
        action.payload.response != null || action.payload.response != undefined
          ? action.payload.response.data
          : action.payload.message;
    },
    logoutRequested: () =>  initialState , 
    clearMessagesRequested: (user) => {
      user.status = undefined;
      user.message = null;
      user.error = {};
    }
  },
});

const {
  loginRequested,
  loginReceived,
  loginRequestFailed,
  forgotPasswordRequested,
  forgotPasswordReceived,
  forgotPasswordRequestFailed,
  logoutRequested,
  clearMessagesRequested,
  setPermissionsRequested,
  setGPSRequested,
  setAppVersionRequested
} = slice.actions;
export default slice.reducer;

// Action Creators

export const login = (email, password) => (dispatch, getState) => {
  console.log("Invoking Login Api with", email, password);
  const state = getState();
  const { latitude, longitude, appversion } = state.entities.user;
  console.log(latitude, longitude);
  dispatch(
    apiCallBegan({
      url: "/api/v1/auth",
      method: "post",
      headers: { latitude, longitude, appversion },
      data: { email, password },
      onStart: loginRequested.type,
      onSuccess: loginReceived.type,
      onError: loginRequestFailed.type,
    })
  );
};

export const forgotPassword = (email) => (dispatch, getState) => {
  console.log("Invoking forgotPassword Api with", email);
  const state = getState();
  const { latitude, longitude, appversion } = state.entities.user;
  console.log(latitude, longitude);
  dispatch(
    apiCallBegan({
      url: "/api/v1/auth/forgot-password",
      method: "post",
      headers: { latitude, longitude, appversion },
      data: { email },
      onStart: forgotPasswordRequested.type,
      onSuccess: forgotPasswordReceived.type,
      onError: forgotPasswordRequestFailed.type,
    })
  );
};

export const logout = () => (dispatch) => {
  console.log("in logout...");
  dispatch(logoutRequested());
};

export const clearMessages = () => (dispatch) => {
  console.log("in clearMessages...");
  dispatch(clearMessagesRequested());
};

export const setPermissions = (permissionName, value) => (dispatch) => {
  console.log("in setPermissions:", permissionName, value);
  dispatch(setPermissionsRequested(permissionName, value));
};

export const setGPS = (latitude, longitude) => (dispatch) => {
  console.log("in setGPS:", latitude, longitude);
  dispatch(setGPSRequested({ latitude, longitude }));
};

export const setAppVersion = (appVersion) => (dispatch) => {
  console.log("in set App Version:", appVersion);
  dispatch(setAppVersionRequested({ appVersion }));
};
