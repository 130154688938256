import React, { memo } from "react";

import { ReactComponent as DownArrow } from "../../assets/images/svg/down_arrow.svg";
import "./filterbar.scss";

const FilterBar = (props) => {
  return (
    <div className="filter-bar">
      <div className="filter-label">Filter</div>
      <div className="vertical_line" />
      <div className="dropdowns">
        <div className="dropdown">
          {/* <DownArrow className="dropdown-icon" /> */}
          <select>
            <option value="country1">Country 1</option>
            <option value="country2">Country 2</option>
            <option value="country3">Country 3</option>
          </select>
        </div>
        <div className="vertical_line" />
        <div className="dropdown">
          {/* <DownArrow className="dropdown-icon" /> */}
          <select>
            <option value="profession1">Profession 1</option>
            <option value="profession2">Profession 2</option>
            <option value="profession3">Profession 3</option>
          </select>
        </div>
        <div className="vertical_line" />
        <div className="dropdown">
          {/* <DownArrow className="dropdown-icon" /> */}
          <select>
            <option value="location1">Location 1</option>
            <option value="location2">Location 2</option>
            <option value="location3">Location 3</option>
          </select>
        </div>
        <div className="vertical_line" />
        <div className="dropdown">
          {/* <DownArrow className="dropdown-icon" /> */}
          <select>
            <option value="jobs1">Jobs 1</option>
            <option value="jobs2">Jobs 2</option>
            <option value="jobs3">Jobs 3</option>
          </select>
        </div>
        <div className="vertical_line" />
        <div className="dropdown">
          {/* <DownArrow className="dropdown-icon" /> */}
          <select>
            <option value="postedBy1">Job Posted By 1</option>
            <option value="postedBy2">Job Posted By 2</option>
            <option value="postedBy3">Job Posted By 3</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default memo(FilterBar);
