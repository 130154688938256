import React, { memo, useContext, useEffect, useMemo, useState } from "react";

import "./Header.scss";
import { ThemeContext } from "../../ThemeContext";
import { ReactComponent as Logo } from "../../assets/images/logo/flyover_logo.svg";
import { ReactComponent as DownArrow } from "../../assets/images/svg/down_arrow.svg";
import { MAX_WIDTH } from "../../utils/constants";
import useWindowDimensions from "../../utils/hook/useWindowDimensions";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const { theme } = useContext(ThemeContext);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (width < MAX_WIDTH) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  }, [width]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  let anchor_tag_style = useMemo(() => {
    return {
      color:
        !isMenuOpen && isSmallScreen
          ? theme.colors.secondary
          : theme.colors.grey,
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeight.medium,
    };
  }, []);

  return (
    <div
      className={`${isMenuOpen ? "topnav" : "topnav responsive"} ${
        isSmallScreen ? "slide-in-down" : ""
      }`}
      id="myTopnav"
      style={{
        display: !isMenuOpen && !isSmallScreen ? "flex" : "block",
        alignItems: !isMenuOpen && !isSmallScreen ? "center" : "inherit",
      }}
    >
      <a href="/home" className="logo">
        <Logo className="main_logo" />
      </a>
      <a href="/" style={anchor_tag_style}>
        Jobs and Skill
      </a>
      <a href="/" style={anchor_tag_style}>
        Community
      </a>
      <a href="/" style={anchor_tag_style}>
        Project Managment
      </a>
      <a href="/" style={anchor_tag_style}>
        Hire Talent
      </a>

      <a
        //href="javascript:void(0);"
        className="icon"
        style={{
          color: !isMenuOpen ? "#fff" : "#000",
          fontSize: "15px",
        }}
        onClick={() => toggleMenu()}
      >
        &#9776;
      </a>
      {isSmallScreen && (
        <div className="dropdown">
          <button className="dropbtn" style={{ color: "#fff" }}>
            for employers
            <DownArrow
              style={{ marginLeft: "0.5vw", width: "1vw" }}
              className="mainArrow"
            />
          </button>
          <div className="dropdown-content">
            <a href="/">Link 1</a>
            <a href="/">Link 2</a>
            <a href="/">Link 3</a>
          </div>
        </div>
      )}
      {isSmallScreen ? (
        isMenuOpen ? null : (
          <div className="right_header_section_mobile">
            <button className="Login">Login</button>
            <button className="Register">Register</button>
          </div>
        )
      ) : (
        <div className="right_header_section">
          <button className="Login">Login</button>
          <button className="Register">Register</button>
          <div className="dropdown">
            <button className="dropbtn">
              for employers
              <DownArrow
                style={{ marginLeft: "0.5vw", width: "1vw" }}
                className="mainArrow"
              />
            </button>
            <div className="dropdown-content">
              <a href="/">Link 1</a>
              <a href="/">Link 2</a>
              <a href="/">Link 3</a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(Header);
