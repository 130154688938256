import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import "./searchbar.scss";

const SearchBar = ({ placeholder, onSearch }) => {
  const handleSearch = (event) => {
    event.preventDefault();
    const searchTerm = event.target.elements.search.value;
    onSearch(searchTerm);
  };

  return (
    <form onSubmit={handleSearch}>
      <div className="search_container">
        <button type="submit" className="search-button">
          <FontAwesomeIcon icon={faSearch} className="search_icon_btn" />
        </button>
        <input
          type="text"
          name="search"
          placeholder={placeholder}
          className="search-input"
        />
      </div>
    </form>
  );
};

export default SearchBar;
