import React from "react";

function LeftCard({ data }) {
  return (
    <div className="left_card_div">
      {data.map((item, i) => {
        return (
          <div className="left_card_item" key={i}>
            <div className="left_card_top_icon_div">{item.icon}</div>
            <div className="left_card_description">{item.description}</div>
          </div>
        );
      })}
    </div>
  );
}

export default LeftCard;
