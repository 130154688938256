import React, { useContext, useState, useEffect, memo } from "react";
import "./manage_network.scss";
import { ReactComponent as RedCheck } from "../../../../assets/images/svg/red_check.svg";
import { ReactComponent as ProjectSvg } from "../../../../assets/images/svg/project.svg";
import { ThemeContext } from "../../../../ThemeContext";
import useWindowDimensions from "../../../../utils/hook/useWindowDimensions";
import { MAX_WIDTH } from "../../../../utils/constants";

function ManageNetwork(props) {
  const { theme } = useContext(ThemeContext);
  const { width } = useWindowDimensions();
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    if (width < MAX_WIDTH) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  }, [width]);

  const Project_Section = () => {
    return (
      <div className="right_section">
        <div className="project_section">
          <ProjectSvg className="project_svg" />
        </div>
        <div>
          <button className="createProjectBtn">post job</button>
        </div>
        <div className="marginTop">
          <span className="desc_txt desc_txt2">
            Create Unique link make your own Resume Database and Contacts
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className="manage_network">
      <div className="manage_grey_body">
        <div className="left_section">
          <div className="title">
            <span style={{ color: theme.colors.red }}>
              Manage & Build Network
            </span>{" "}
            for Your Ongoing Recruitment.
          </div>
          <span className="desc_txt marginTop">
            <RedCheck className="check_icon" />
            Build Independent Resume Data base
          </span>
          <span className="desc_txt">
            <RedCheck className="check_icon" />
            Get Relevant Cv’s Basket
          </span>
          <span className="desc_txt">
            <RedCheck className="check_icon" />
            Skill Provider / Associate Connection
          </span>
          <span className="desc_txt">
            <RedCheck className="check_icon" />
            Advance and easy Dashboard
          </span>
          <span className="desc_txt">
            <RedCheck className="check_icon" />
            Accurate Update on Whatsapp
          </span>
        </div>
        {!isSmallScreen && (
          <div>
            <Project_Section />
          </div>
        )}
      </div>
      {isSmallScreen && (
        <div>
          <Project_Section />
        </div>
      )}
    </div>
  );
}

export default memo(ManageNetwork);
