import React, { useContext } from "react";
import "./mega_menu.scss";
import { ThemeContext } from "../../../../ThemeContext";
import PopupModal from "../../../../components/PopupModal/PopupModal";
import {
  feature_data,
  prize_solution_data,
  resource_data,
} from "../../../../utils/constants";

function MegaMenu() {
  const { theme } = useContext(ThemeContext);

  return (
    <div style={{ backgroundColor: theme.colors.primary }}>
      <ul className="main-nav">
        <li className="top-level-link">
          <a className="mega-menu">
            <span>Feature</span>
          </a>
          <div className="sub-menu-block" id="five-block">
            <div className="row">
              <div className="col-md-15 col-sm-6">
                <PopupModal data={feature_data} />
              </div>
            </div>
          </div>
        </li>
        <li className="top-level-link">
          <a className="mega-menu">
            <span>Resources</span>
          </a>
          <div className="sub-menu-block">
            <div className="row">
              <div className="col-md-15 col-sm-6">
                <PopupModal data={resource_data} />
              </div>
            </div>
          </div>
        </li>
        <li className="top-level-link">
          <a className="mega-menu">
            <span>Prize and solution</span>
          </a>
          <div className="sub-menu-block">
            <div className="row">
              <div className="col-md-15 col-sm-6">
                <PopupModal data={prize_solution_data} />
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default MegaMenu;
