import React, { memo, useContext, useState } from "react";
import "./global_company.scss";
import { ReactComponent as ManSvg } from "../../../../assets/images/svg/man.svg";
import { ReactComponent as GrpSvg } from "../../../../assets/images/svg/group.svg";
import { ThemeContext } from "../../../../ThemeContext";

function GlobalCompany(props) {
  const { theme } = useContext(ThemeContext);
  const [global_company, setGlobal] = useState([
    {
      id: 1,
      title: "# Registered MEA Agency",
    },
    {
      id: 2,
      title: " Mediator for company Visa",
    },
    {
      id: 3,
      title: "Experts ",
    },
    {
      id: 4,
      title: "Looking Visa",
    },
    {
      id: 5,
      title: "Mediator for company Visa",
    },
    {
      id: 6,
      title: "Looking Visa",
    },
    {
      id: 7,
      title: "Looking Visa",
    },
    {
      id: 8,
      title: " Mediator for company Visa",
    },
    {
      id: 9,
      title: "Looking Visa",
    },
    {
      id: 10,
      title: "# Registered MEA Agency",
    },
    {
      id: 11,
      title: "Experts ",
    },
    {
      id: 12,
      title: "Looking Visa",
    },
    {
      id: 13,
      title: "Mediator for company Visa",
    },
    {
      id: 14,
      title: "Looking Visa",
    },
    {
      id: 15,
      title: "Looking Visa",
    },
    {
      id: 16,
      title: " Mediator for company Visa",
    },
    {
      id: 17,
      title: "Looking Visa",
    },
  ]);

  return (
    <div className="global_company_container">
      <div className="global_company_body">
        <div className="first_section">
          <div className="title">
            Join the Global{" "}
            <span style={{ color: theme.colors.primary }}>#Trending</span>{" "}
            Companies and jobs{" "}
            <span style={{ color: theme.colors.primary }}>Community</span>
          </div>
          <div className="global_grp1">
            {global_company.map((item, i) => {
              return (
                <div key={i} className="global_grp">
                  <button className="popular_btn">{item.title}</button>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(GlobalCompany);
