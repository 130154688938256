import React, { memo, useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import "../styles/section2.scss";

import down_arrow from "../../../assets/images/image/down_arrow_white.png";
import { ReactComponent as WhatsAppLogo } from "../../../assets/images/logo/logos_whatsapp.svg";
import { ThemeContext } from "../../../ThemeContext";
import Filter from "./FilterSection/Filter";
import TodayPopular from "./TodayPopular/TodayPopular";
import PopularCard from "./PopularSectionCard/PopularSectionCard";

function Section2(props) {
  const { theme } = useContext(ThemeContext);

  return (
    <div className="section2">
      <div className="main_body animate__animated animate__slideInUp">
        <div className="card">
          <div className="title">
            From <span style={{ color: theme.colors.red }}>Apply Job</span> to
            Arrive Designation, Every Thing on Whatsapp{" "}
          </div>
          <div className="whatsappLinkBtnDiv">
            <button className="whatsappLinkBtn">
              <WhatsAppLogo className="whatsappLogo" /> w.a/Whatsapp link
            </button>
          </div>
          <div className="desc">
            Click on below link from your mobile, Apply for the job and start
            get update on whatsup
          </div>
        </div>
        <div className="grey_body">
          <div className="grey_body_inner">
            {/* <div className="title">
              From <span style={{ color: theme.colors.red }}>Apply Job</span> to
              Arrive Designation, Every Thing on Whatsapp{" "}
            </div> */}
            <div className="filter_section">
              <Filter />
            </div>
            <div className="popular_section">
              <TodayPopular />
              <PopularCard />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(Section2);
