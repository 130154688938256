import React, { memo, useState } from "react";
import "./today_popular.scss";
import { ReactComponent as ManSvg } from "../../../../assets/images/svg/man.svg";
import { ReactComponent as GrpSvg } from "../../../../assets/images/svg/group.svg";

function TodayPopular(props) {
  const [today_popular, setPopular] = useState([
    {
      id: 1,
      title: "Canada",
    },
    {
      id: 2,
      title: "Saudi Arabia jobs",
    },
    {
      id: 3,
      title: "Interview ",
    },
    {
      id: 4,
      title: "Electrician",
    },
    {
      id: 5,
      title: "Oil & Gas Industry",
    },
    {
      id: 6,
      title: "Saudi Bin Laden Company",
    },
    {
      id: 7,
      title: "Saudi Bin Laden Company",
    },
    {
      id: 8,
      title: "National Exports Manpower Consultant",
    },
  ]);

  return (
    <div className="today_popular_container">
      <div className="today_body">
        <div className="first_section">
          <div className="title">#Today’s Popular</div>
          <div className="today_grp1">
            {today_popular.map((item, i) => {
              return (
                <div key={i} className="today_grp">
                  <button className="popular_btn">{item.title}</button>
                </div>
              );
            })}
          </div>
        </div>
        <div className="grp_img">
          <ManSvg className="manSVG" />
          <GrpSvg className="grpSVG" />
        </div>
      </div>
    </div>
  );
}

export default memo(TodayPopular);
