import React, { memo } from "react";
import { ReactComponent as FaSearch } from "../../assets/images/svg/search.svg";
import "./search_input.scss";

const SearchInput = () => {
  return (
    <div className="search-container">
      <input
        type="text"
        className="search-input"
        placeholder="Job, Location, etc."
      />
      <div className="search-icon">
        <button className="search_btn">
          <FaSearch className="searchSVG" />
        </button>
      </div>
    </div>
  );
};

export default memo(SearchInput);
