import React, { createContext, useState, useEffect } from "react";
import theme from "./theme";

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [currentTheme, setCurrentTheme] = useState(theme);

  const setTheme = (newTheme) => {
    setCurrentTheme(newTheme);
  };

  useEffect(() => {
    const handleResize = () => {
      let fontSize;
      const { innerWidth } = window;

      if (innerWidth < 768) {
        fontSize = theme.fontSizes.medium.mobile;
      } else if (innerWidth >= 768 && innerWidth < 1024) {
        fontSize = theme.fontSizes.medium.tablet;
      } else {
        fontSize = theme.fontSizes.medium.desktop;
      }

      setCurrentTheme((prevTheme) => ({
        ...prevTheme,
        fontSizes: {
          ...prevTheme.fontSizes,
          medium: {
            ...prevTheme.fontSizes.medium,
            current: fontSize,
          },
        },
      }));
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <ThemeContext.Provider value={{ theme: currentTheme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
