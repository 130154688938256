import React from "react";
import LeftCard from "./LeftCard";
import "./slider_container.scss";
import RightSec1 from "../RightSec1";
import RightSec2 from "../RightSec2";
import RightSec3 from "../RightSec3";
import RightSec4 from "../RightSec4";
import RightSec5 from "../RightSec5";

function SliderContainer({ data, slider_content, slider }) {
  return (
    <div className="slider_inner_container">
      <div className="slider_container_body">
        <div className="left_section">
          <LeftCard data={data} />

          {slider_content && (
            <div className="slider_bottom_sticky_Section">
              <div className="cv_bank_div">
                <button type="submit" className="cv_Bank_btn">
                  Cv’s Bank
                </button>
                <div className="center_card_description">
                  Build independent Resume Data bank, complete any industries
                  project before deadline
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="right_section">
          {slider == 1 && <RightSec1 />}
          {slider == 2 && <RightSec2 />}
          {slider == 3 && <RightSec3 />}
          {slider == 4 && <RightSec4 />}
          {slider == 5 && <RightSec5 />}
        </div>
      </div>
    </div>
  );
}

export default SliderContainer;
