import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import api from "./middleware/api";
import reducer from "./reducer";
import { persistStore, persistReducer } from "redux-persist";
import { createLogger } from "redux-logger";
import storage from "redux-persist/lib/storage";

const sensitiveStorageOptions = {
  sharedPreferencesName: "in.flyover",
  keychainService: "in.flyover",
};
const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: ["entities", "user", "flag_state"], //Things u want to persist
  blacklist: [], //Things u dont
};

// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
    createLogger(),
    api,
  ],
});

let persistor = persistStore(store);

export { store, persistor };
