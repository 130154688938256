import React from "react";
import { ReactComponent as Slide41SVG } from "../../../assets/images/svg/slide4_right_4_1.svg";
import { ReactComponent as Slide411SVG } from "../../../assets/images/svg/slide4_right_4_1_1.svg";
import { ReactComponent as Slide412SVG } from "../../../assets/images/svg/slide4_right_4_1_2.svg";

function RightSec4() {
  return (
    <div className="details_div">
      <div className="details_title">Communication</div>
      <div className="details_desc">
        Experience a whole new way of connecting, where communication is
        seamless and efficient. Say yes to simplicity, productivity, and smart
        communication. Say goodbye to miscommunication, spam users, and
        repetitive tasks and messages. Stay in the loop and work smart with
        ease. Work smart, not hard, and enjoy a seamless and efficient
        communication experience.
      </div>
      <div className="transparent_btn_div">
        <button type="submit" className="transparent_btn">
          Start Messaging
        </button>
        <div className="slide4_right_4_div">
          <div>
            <Slide41SVG className="slide4_1_svg" />
          </div>
          <div className="right_svg_slide4_div">
            <Slide411SVG className="slide4_11_svg" />
            <Slide412SVG className="slide4_12_svg" />
          </div>
        </div>
      </div>
      {/* <div className="profile_right_div"></div> */}
    </div>
  );
}

export default RightSec4;
