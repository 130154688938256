import React, { useContext } from "react";

import { ThemeContext } from "../../../ThemeContext";
import "../styles/man_pow_sec4.scss";
import { ReactComponent as CVBankSvg } from "../../../assets/images/svg/cv_Bank_right.svg";
import { ReactComponent as CVBankFT1Svg } from "../../../assets/images/svg/cv_bk_ft_1.svg";

import { ReactComponent as Intw1Svg } from "../../../assets/images/svg/intw_1.svg";

import { ReactComponent as Intw2Svg } from "../../../assets/images/svg/intw_2.svg";

import { ReactComponent as Intw3Svg } from "../../../assets/images/svg/intw_3.svg";

function ManPowerSection4() {
  const { theme } = useContext(ThemeContext);

  let data = [
    {
      id: 1,
      icon: <CVBankFT1Svg className="cv_bank_db_svg" />,
      title: "Place Recommendation & Interview Forcaste",
      description:
        "We provide a glimpse of the interview success rate based on past history in similar categories, and also recommend places where the interview is likely to be successful.",
    },
    {
      id: 2,
      icon: <CVBankFT1Svg className="cv_bank_db_svg" />,
      title: "Highly Rated Interview venue and Trade center",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Interdum porta magna at blandit a. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Interdum porta magna at blandit a.",
    },
    {
      id: 3,
      icon: <CVBankFT1Svg className="cv_bank_db_svg" />,
      title: "Project Sucess Assuarance",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Interdum porta magna at blandit a. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Interdum porta magna at blandit a.",
    },
    {
      id: 4,
      icon: <CVBankFT1Svg className="cv_bank_db_svg" />,
      title: "Pre and During Selection’s Campaign Manager",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Interdum porta magna at blandit a. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Interdum porta magna at blandit a.",
    },
    {
      id: 5,
      icon: <CVBankFT1Svg className="cv_bank_db_svg" />,
      title: "200% Walkin Applicant Data Base on Deep Analysis",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Interdum porta magna at blandit a. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Interdum porta magna at blandit a.",
    },
  ];

  return (
    <div className="man_pow_sec4">
      <div className="man_pow_sec4_body">
        <div className="title_div_cv_bank">
          <div className="title">Interview Campaign solution</div>
        </div>
      </div>
      <div className="cv_bank_center_div">
        <div className="transparent_btn_div">
          <div className="cv_bank_left_div">
            <Intw1Svg className="cv_bank_svg" />
            <Intw2Svg className="cv_bank_svg" />
            <Intw3Svg className="cv_bank_svg" />
          </div>
        </div>
        <div className="cv_bank_svg_div">
          {data.map((item, i) => {
            return (
              <div className="intw_right_inner" key={i}>
                <div>{item.icon}</div>
                <div className="intw_right_inner2">
                  <div className="title4">{item.title}</div>
                  <div className="title3">{item.description}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div>
        <button type="submit" className="transparent_btn">
          Start Interview Campaign
        </button>
      </div>
    </div>
  );
}

export default ManPowerSection4;
