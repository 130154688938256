import React, { useContext } from "react";

import { ThemeContext } from "../../../ThemeContext";
import "../styles/man_pow_sec3.scss";
import { ReactComponent as CVBankSvg } from "../../../assets/images/svg/cv_Bank_right.svg";
import { ReactComponent as CVBankDBSvg } from "../../../assets/images/svg/cv_bank_db.svg";

import { ReactComponent as CVBankFT1Svg } from "../../../assets/images/svg/cv_bk_ft_1.svg";

import { ReactComponent as CVBankFT2Svg } from "../../../assets/images/svg/cv_bk_ft_2.svg";

import { ReactComponent as CVBankFT3Svg } from "../../../assets/images/svg/cv_bk_ft_3.svg";

function ManPowerSection3() {
  const { theme } = useContext(ThemeContext);
  return (
    <div className="man_pow_sec3">
      <div className="man_pow_sec3_body">
        <div className="title_div_cv_bank">
          <div className="title">Cv’s Bank Network</div>
        </div>
        <div className="title_div_cv_bank">
          <div className="title2" style={{ color: "#808080" }}>
            Let your client to select a candidate from your{" "}
            <span style={{ color: theme.colors.red }}>
              Personal Resume Databank
            </span>{" "}
            simply by inviting them to access your database
          </div>
        </div>
      </div>
      <div className="cv_bank_center_div">
        <div className="transparent_btn_div">
          <div className="cv_bank_left_div">
            <div>
              <CVBankDBSvg className="cv_bank_db_svg" />
            </div>
            <div className="title4">Personal Cv’s Bank</div>
            <div className="title3">
              Build your Own Database Domain, Acessible only to you, become the
              Self Dependent for your own Project, close the project Dominate
              the Recruitment
            </div>
            <button type="submit" className="transparent_btn">
              Create Cv’s Bank
            </button>
          </div>
        </div>
        <div className="cv_bank_svg_div">
          <CVBankSvg className="cv_bank_svg" />
        </div>
      </div>
      <div className="cv_bank_footer_div">
        <div className="cv_bank_footer_div_inner">
          <div>
            <CVBankFT1Svg className="cv_bank_db_svg" />
          </div>
          <div className="title4">Personal Cv’s Bank</div>
          <div className="title3 textAlignLeft">
            Complete a Signup journey as a Recruiter or Skill agent
          </div>
        </div>
        <div className="cv_bank_footer_div_inner">
          <div>
            <CVBankFT2Svg className="cv_bank_db_svg" />
          </div>
          <div className="title4">Personal Cv’s Bank</div>
          <div className="title3 textAlignLeft">
            Complete a Signup journey as a Recruiter or Skill agent
          </div>
        </div>
        <div className="cv_bank_footer_div_inner">
          <div>
            <CVBankFT3Svg className="cv_bank_db_svg" />
          </div>
          <div className="title4">Personal Cv’s Bank</div>
          <div className="title3 textAlignLeft">
            Complete a Signup journey as a Recruiter or Skill agent
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManPowerSection3;
